import { createClasses } from 'styles';
import Typography from '@mui/material/Typography';

const useClasses = createClasses((theme, { disableGutterBottom, spacingType = 'gutter' }) => ({
	root: {
		...theme.mixins.typography.heading,
		...(!disableGutterBottom && ({
			marginBottom: {
				...theme.config[`${spacingType}Spacing`],
				xs: 1.5
			}
		})),
		width: '100%',
		textAlign: 'center',
		mb: {
			xs: 1,
			md: 2
		},
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.up('phone')]: {
			'&::before, &::after': {
				content: '""',
				width: '50%',
				position: 'relative',
				display: 'inline-flex',
				flex: '1 0 auto',
				backgroundColor: 'currentColor',
				background: 'color-mix(in srgb, currentColor 50%, transparent)',
				height: '1px',
				marginTop: '1px'
			},
			'&::before': {
				right: theme.spacing(3),
				marginLeft: '-50%'
			},
			'&::after': {
				left: theme.spacing(3),
				marginRight: '-50%'
			}
		}
	}
}), {
	name: 'PageMainHeading'
});

export default function PageMainHeading(props) {
	const classes = useClasses(props);

	const {
		children,
		...rest
	} = props;

	return (
		<Typography
			variant="h3"
			component="h1"
			{...rest}
			className={classes.root}
		>
			{children}
		</Typography>
	);
}
