import { useTheme } from 'styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PageSection from 'components/PageSection';
import BrandProductLogo from './FactoryBrandProductLogo';

export default function FactoryBrandProductsSection(props) {
	const theme = useTheme();
	const { darkMode, containerSpacing } = theme.config;

	return (
		<PageSection {...props}>
			<Stack
				direction={{md: 'row'}}
				alignItems="center"
				gap={{
					...containerSpacing,
					md: 6,
					lg: 8
				}}
				maxWidth="lg"
			>
				<BrandProductLogo
					style={{maxWidth: 200}}
					darkMode={darkMode}
				/>
				<Stack>
					<Typography variant="h5" component="h2" fontWeight="bold" mb={2.5}>
						Erzholz — Produkte aus dem Erzgebirge
					</Typography>
					<Typography
						maxWidth="lg"
						fontSize={theme.config.fontSize}
						mb={1.5}
					>
						Erzholz ist unsere hauseigene Marke und beschreibt Produkte, die von uns oder Herstellern aus dem Erzgebirge stammen und zum Großteil aus Materialien der Region hergestellt worden sind.
					</Typography>
					<Typography
						maxWidth="lg"
						fontSize={theme.config.fontSize}
					>
						Es ist unsere Vision und Leidenschafaft so viel wie wir können in unserer Heimat, dem schönen Erzgebirge zu produzieren und regionale Betriebe und Künstler zu unterstützen.
					</Typography>
				</Stack>
			</Stack>
		</PageSection>
	);
}
