import { useTheme } from 'styles';
import HtmlParser from 'react-app/components/HtmlParser';

export default function PageHtmlParser(props) {
	const theme = useTheme();
	const { containerSpacing } = theme.config;

	const {
		children,
		section,
		html: htmlProp,
		...rest
	} = props;

	const html = htmlProp || section?.html;

	return (
		(html || children) && (
			<HtmlParser
				marginBottom={containerSpacing}
				{...rest}
				html={html}
			>
				{children}
			</HtmlParser>
		)
	);
}
