import * as React from 'react';
import PropTypes from 'prop-types';
import ScrollableCards from 'components/ScrollableCards';
import RelatedCard from 'components/RelatedCard';

const defaultProps = {
	cardSizes: {xs: 300, sm: 360}
};

function RelatedCarousel(props) {
	const {
		CardComponent = RelatedCard,
		cardSizes = defaultProps.cardSizes,
		...rest
	} = props;

	return (
		<ScrollableCards
			{...rest}
			cardSizes={cardSizes}
			CardComponent={CardComponent}
		/>
	);
}

RelatedCarousel.propTypes = {
	cards: PropTypes.array.isRequired
};

export default React.memo(RelatedCarousel);
