import { useOutletContext } from 'react-router-dom';
import WatchIcon from '@mui/icons-material/WatchLaterOutlined';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import GroupIcon from '@mui/icons-material/Group';
import Stack from '@mui/material/Stack';
import ContactIcon from '@mui/icons-material/QuestionAnswer';
import AlertBox from 'react-app/components/AlertBox';
import Button from 'components/Button';
import AppContent from 'components/AppContent';
import PageHeadline from 'components/PageHeadline';
import PageMainHeading from 'components/PageMainHeading';
import PageContainer from 'components/PageContainer';
import PageHtmlParser from 'components/PageHtmlParser';
import CardContainer from 'components/CardContainer';
import AssortmentCard from 'components/AssortmentCard';
import NewsSection from 'components/News/NewsSection';
import ImageGallery from 'components/ImageGallery';
import ContactSection from 'components/ContactSection';
import RelatedSection from 'components/RelatedSection';
import SaleSection from 'components/Sale/SaleSection';
import HeroBanner from './FactoryHomeHeroBanner';
import BrandProductsSection from './FactoryBrandProductsSection';
import locationImg from 'images/cards/location/holzfabrik-erzgebirge.jpg';
import { appData } from 'config/data.cjs';

const { pages, isFactory } = appData;

const CardContainerProps = {
	gridMode: true,
	gridTemplateColumns: {
		xs: 'repeat(2, 1fr)',
		md: 'repeat(3, 1fr)',
		desktop: 'repeat(4, 1fr)'
	}
};

export default function FactoryHomeRoute() {
	const { id, title, headline, sections, cards, related, data } = useOutletContext();
	const { saleData: {data: saleCards = []} = {} } = data || {};
	const { newsData } = data || {};

	const { galleries } = sections || {};
	const hasGallery = Boolean(galleries?.main?.length);
	const hasRelatedCards = Boolean(related?.cards?.length);
	const hasSaleCards = Boolean(Array.isArray(saleCards) && saleCards.length);

	return (
		<AppContent>
			<HeroBanner/>
			<PageContainer>
				{isFactory ? (
					<PageContainer>
						<PageMainHeading>
							{headline || title}
						</PageMainHeading>
					</PageContainer>
				) : (
					<PageHeadline centered title={title} headline={headline} component="h1"/>
				)}
				<PageHtmlParser
					maxWidth="md"
					marginX="auto"
					textAlign="center"
					section={sections?.intro}
				>
					<AlertBox type="primary" icon={<WatchIcon/>}>
						Montag - Freitag von 9 - 17 Uhr | Samstag von 9 - 12 Uhr
					</AlertBox>
				</PageHtmlParser>
			</PageContainer>
			<PageHeadline title="Unser Indoor-Sortiment"/>
			<CardContainer
				cards={cards.indoor}
				CardComponent={AssortmentCard}
				{...CardContainerProps}
			/>
			<PageHeadline gutterTop title="Unser Outdoor-Sortiment"/>
			<CardContainer
				cards={cards.outdoor}
				CardComponent={AssortmentCard}
				{...CardContainerProps}
			/>
			{hasSaleCards && (
				<SaleSection
					subtitle="Geschenkartikel in unserem Online-Shop"
					disableGutterBottom={false}
					pageId={id}
					cards={saleCards}
					PageHeadlineProps={{centered: true}}
				>
					<Button
						size="large"
						variant="contained"
						sx={{marginX: 'auto', mt: {xs: .25, md: 1}}}
						href={pages.giftItems.pathname}
						startIcon={<GiftIcon/>}
					>
						Alle Geschenkartikel
					</Button>
				</SaleSection>
			)}
			{newsData?.data?.length > 0 && (
				<NewsSection
					spacingType="container"
					title={pages.news.headline}
					cards={newsData.data}
				/>
			)}
			<PageContainer gutterTop centered>
				<ContactSection
					name="Kontakt zu uns"
					email={appData.contact.email.lauter4}
					phone={appData.contact.phone.lauter4}
					map={appData.contact.map.lauter4}
					company={appData.company.title.lauter4}
					address={appData.contact.address.lauter4.join(', ')}
					imageSrc={locationImg}
				/>
				<Stack marginTop={2} direction="row" gap={2} flexWrap="wrap" justifyContent="center">
					<Button
						size="large"
						variant="contained"
						href={pages.contact.pathname}
						startIcon={<ContactIcon/>}
					>
						Kontaktformular
					</Button>
					<Button
						size="large"
						variant="contained"
						href={pages.team.pathname}
						startIcon={<GroupIcon/>}
					>
						Unser Team
					</Button>
				</Stack>
			</PageContainer>
			<PageContainer>
				<PageHtmlParser
					maxWidth="lg"
					section={sections?.main}
					marginTop={2}
					marginBottom={0}
				/>
			</PageContainer>
			<BrandProductsSection gutterTop/>
			{hasRelatedCards && (
				<RelatedSection
					title={`Mehr zum Thema ${title}`}
					cards={related.cards}
				/>
			)}
			{hasGallery && (
				<PageContainer gutterTop>
					<ImageGallery size={120} images={galleries.main}/>
				</PageContainer>
			)}
		</AppContent>
	);
}
