import * as React from 'react';
import CarouselSection from 'components/CarouselSection';
import RelatedCarousel from 'components/RelatedCarousel';

function RelatedSection({title, ...rest}) {
	return (
		<CarouselSection
			{...rest}
			subtitle={title}
			CarouselComponent={RelatedCarousel}
		/>
	);
}

export default React.memo(RelatedSection);
